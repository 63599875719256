import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import event from "../../../api/event";
import {
  Anchor,
  Badge,
  LoadingOverlay,
  SimpleGrid,
  Button,
  Grid,
  Col,
  Spoiler,
  Breadcrumbs,
  Text,
  Divider,
  ActionIcon,
  Tooltip,
} from "@mantine/core";
import { User } from "../../../entity/User";
import { Modal } from "@mantine/core";
import users from "../../../api/users";
import creditHistory from "../../../api/creditHistory";
import ButtonWrapper from "../../../components/paypal/ButtonWrapper";
import { useNotifications } from "@mantine/notifications";
import storage from "../../../auth/storage";
import { useModals } from "@mantine/modals";
import { CheckIcon } from "@heroicons/react/solid";
import { FaUser } from "react-icons/fa";
import {
  FiUserCheck,
  FiUserX,
  IoIosCopy,
  IoMdOpen,
  MdOutlineCancel,
} from "react-icons/all";
import { useClipboard, useWindowScroll } from "@mantine/hooks";
import { RiFolderSharedLine } from "react-icons/ri";
import profilePicture from "../../../assets/images/profile-picture-test.png";
import graphTest from "../../../assets/images/test-graph.png";
import CardCollection from "../../../components/event/CardCollection";
import HasRoles from "../../../components/Security/hasRoles";
import { ROLE_DXMAN_MORE, ROLE_XMAN } from "../../../constants/roles";
import { FaCopy } from "react-icons/fa";
import Footer from "../../Footer";
import { CSVLink, CSVDownload } from "react-csv";
import workshop from "../../../api/workshop";
import teamMeeting from "../../../api/teamMeeting";
import { format_d_MMMM_YYYY, format_dddd_d_MMMM_YYYY } from "../../../utility/dateUtility";

import transactions from "../../../api/transactions";

const ItemEventWrapped = ({
  setopenedPaypal,
  setDataEvent,
  setTotalPrice,
  openedPaypal,
  dataEvent,
  totalPrice,
  Completed,
  setCompleted,

  requestLoading,
  setRequestLoading,
  request,
  loading,
}:any) => {
  const { id } = useParams();
  const user: any = storage.getUser();
  const modals = useModals();
  const [isGuest, setIsGuest] = useState();
  const [isCopilot, setIsCopilot] = useState();
  const [isFormer, setIsFormer] = useState(Boolean);
  const [godFatherEmails, setGodFatherEmails] = useState<any[]>([]);
  const [isInWaitingZone, setIsInWaitingZone] = useState(false);
  const [canSeeGuest, setCanSeeGuest] = useState(false);
  const [csv, setCsv] = useState<any[]>([]);
  const notifications = useNotifications();
  const clipboard = useClipboard({ timeout: 800 });
  const clipboard2 = useClipboard({ timeout: 800 });
  const navigation = useNavigate();
  const [opened, setOpened] = useState(false);
  const [opened2, setOpened2] = useState(false);
  const [scroll, scrollTo] = useWindowScroll();
  const [isExternLink, setIsExternLink] = useState(false);
  const { data: dataUser, request: requestGetUser } = useApi(users.me);
  const [clickedLink, setclickedLink] = useState(true);
  const { request: requestEditUser } = useApi(users.edit);
  const [creditId, setCreditId] = useState<string | number | null>(null);
  const [transactionData, setTransactionData] = useState<{ id: number } | null>(null);
  const {request: getTransactionByAtelierUser} = useApi(transactions.getTransactionByAtelierUser);
  const {request: getAvoirByUser} = useApi(transactions.getAvoirByUser);


  // console.log('dataEvent', dataEvent)

  useEffect(() => {
    window.scrollTo(0, 0);
    requestGetUser();
  }, []);

  useEffect(() => {
    // @ts-ignore
    setIsGuest(!!dataEvent?.guests.find((guest) => guest.id === user?.id));
    // @ts-ignore
    setIsInWaitingZone(!!dataEvent?.waitingZone.find((guest) => guest.id === user?.id),);
    // @ts-ignore
    setIsCopilot(!!dataEvent?.coPilotWanted.find((coPilota) => coPilota.id === user?.id),);

    setIsExternLink(!!dataEvent?.externLink);

    setIsFormer(dataEvent?.former?.id === user?.id);

    setCsv(
        dataEvent?.guests.map((guest: any) => {
          return {
            prenom: guest.firstName,
            nom: guest.lastName,
            email: guest.email,
            telephone: "'" + guest.phoneNumber,
            parrain_prenom: guest.godFather
                ? guest.godFather?.firstName
                : "pas de parrain",
            parrain_nom: guest.godFather
                ? guest.godFather?.lastName
                : "pas de parrain",
            telephone_parrain:
                "'" + guest.godFather?.phoneNumber || "pas de parrain",
          };
        })
    );
    getTransactionByAtelierUser({user_id:user.id, atelier_id:id}).then((res: any) => {
        let result = res.data;
        let avoir = null;
        if (Array.isArray(result) && result.length > 0) {
            const transaction = result[0];
            if(transaction && transaction.avoir){
              avoir = transaction.avoir;
              setCreditId(transaction.avoir);
            }
            setTransactionData(transaction);
        } else if (typeof result === 'object' && result !== null) {
            if(result && result.avoir){
              avoir = result.avoir;
              setCreditId(result.avoir);
            }
            setTransactionData(result);
        } else {
            setTransactionData(null); // Si pas de transactions, réinitialiser l'état
        }
        if(!avoir){
          getAvoirByUser({user_id:user.id, atelier_id:id}).then((res: any) => {
              let result = res.data;
              if(result && result.avoir){
                setCreditId(result.avoir);
              }
          });
        }
    });
  }, [dataEvent]);

  useEffect(() => {
    if (user.id == dataEvent?.former?.id) setCanSeeGuest(true)
    if (user.role[0] == "ROLE_XMAN") setCanSeeGuest(true)
    if (user.role[0] == "ROLE_MAN") setCanSeeGuest(true)
    if (user.role[0] == "ROLE_DXMAN") setCanSeeGuest(true)
    if (user.role[0] == "ROLE_DEVMAN") setCanSeeGuest(true)
    if (isGuest) setCanSeeGuest(true)
  }, [dataEvent, isGuest]);

  useEffect(() => {
    if (dataEvent?.guests?.length > 0) {
      const godFather: any[] = [];
      dataEvent.guests.forEach((guest: any) => {
        if (guest.godFather) {
          godFather.push(
            dataEvent.guests.filter((g: any) => g.id === guest.godFather.id),
          );
        }
      });
      setGodFatherEmails(godFather);
    }
  }, [dataEvent?.guests]);

  useEffect(() => {
    if (Completed === true) {
      setopenedPaypal(false);
      const register = async () => {
        setRequestLoading(true);
        const result = await event.registerGuest(Number(id));
        if (!result.ok) {
          dataUser.credit = dataUser.credit + totalPrice;
          requestEditUser({ credit: dataUser.credit }, dataUser.id).then(() => {
            creditHistory.save({
              user: dataUser.id,
              amount: totalPrice,
              type: "crédit",
              description: "Echec d'une inscription à un évènement",
              action: "error"
            }).then((response: any) => {
                const credit_id = response.data?.id;
                if (credit_id && id) {
                    setCreditId(credit_id);
                    creditHistory.updateTransactionCredit({
                        user_id: dataUser.id,
                        atelier: Number(id),
                        credit: credit_id
                    }).then((res:any) => {
                    }).catch(error => {
                    });
                }
            })
          });
          notifications.showNotification({
            title: "Une erreur est survenue.",
            message: `L'inscription n'a pas pu aboutir ! 🤥`,
            color: "red",
          });
        } else {
          if (dataUser.credit > 0) {
            dataUser.credit =
                dataUser.credit - (dataEvent.price - totalPrice);
            requestEditUser({ credit: dataUser.credit }, dataUser.id).then(() => {
              creditHistory.save({
                user: dataUser.id,
                amount: - (dataEvent.price - totalPrice),
                type: "débit",
                description: "Inscription à un évènement",
                action: "register"
              }).then((response: any) => {
                const credit_id = response.data?.id;
                if (credit_id && id) {
                    setCreditId(credit_id);
                    creditHistory.updateTransactionCredit({
                        user_id: dataUser.id,
                        atelier: Number(id),
                        credit: credit_id
                    }).then((res:any) => {
                    }).catch(error => {
                    });
                }
            })
            });
          }
          notifications.showNotification({
            title: "Nice !",
            message: "Vous êtes maintenant inscrit ! 😀",
            color: "green",
          });
        }
      };
      register().then(() => {
        request(id).then((r:any) => {
          setDataEvent(r.data);
        });
        requestGetUser();
      });
      setCompleted(false);
      setRequestLoading(false);
    }
  }, [Completed]);

  const handleGuestFree = async () => {
    if (isGuest) {
      setRequestLoading(true);
      const result = await event.unRegisterGuest(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: "L'annulation n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        notifications.showNotification({
          title: "Nice !",
          message: "Vous n'êtes maintenant plus inscrit ! 😀",
          color: "green",
        });
        setRequestLoading(false);
        request(id).then((r:any) => {
          setDataEvent(r.data);
          requestGetUser();
        });
      }
    }
    if (!isGuest) {
      const result = await event.registerGuest(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: `L'inscription n'a pas pu aboutir ! 🤥`,
          color: "red",
        });
      } else {
        notifications.showNotification({
          title: "Nice !",
          message: "Vous êtes maintenant inscrit ! 😀",
          color: "green",
        });
      }
      request(id).then((r:any) => {
        setDataEvent(r.data);
        requestGetUser();
      });
    }
  }

  const handleGuest = async () => {
    if (isGuest) {
      setRequestLoading(true);
      const result = await event.unRegisterGuest(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: "L'annulation n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        const date1 = new Date(dataEvent?.startDate);
        const date2 = new Date();
        // @ts-ignore
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays <= 7) {
          notifications.showNotification({
            title: "Nice !",
            message: "Vous n'êtes maintenant plus inscrit ! 😀",
            color: "green",
          });
          request(id).then((r:any) => {
            setDataEvent(r.data);
            requestGetUser();
          });
          setRequestLoading(false);
        } else {
          const newCredit = dataUser.credit + dataEvent.price;
          requestEditUser({ credit: newCredit }, dataUser.id).then(() => {
            creditHistory.save({
              user: dataUser.id,
              amount: dataEvent.price,
              type: "crédit",
              description: "Remboursement des frais d'inscription à un évènement",
              action: "cancel"
            }).then((response: any) => {
                const credit_id = response.data?.id;
                if (credit_id && id) {
                    setCreditId(credit_id);
                    creditHistory.updateTransactionCredit({
                        user_id: dataUser.id,
                        atelier: Number(id),
                        credit: credit_id
                    }).then((res:any) => {
                    }).catch(error => {
                    });
                }
            })
          });
          notifications.showNotification({
            title: "Nice !",
            message: "Vous n'êtes maintenant plus inscrit ! 😀",
            color: "green",
          });
          request(id).then((r:any) => {
            setDataEvent(r.data);
            requestGetUser();
          });

          setRequestLoading(false);
        }
      }
    }
    if (!isGuest) {
      setTotalPrice(dataEvent?.price);
      if (dataUser?.credit > 0) {
        if (dataUser?.credit >= dataEvent?.price) {
          const result = await event.registerGuest(Number(id));
          if (!result.ok) {
            notifications.showNotification({
              title: "Une erreur est survenue.",
              message: `L'inscription n'a pas pu aboutir ! 🤥`,
              color: "red",
            });
          } else {
            const newCredit = dataUser.credit - dataEvent.price;
            requestEditUser({ credit: newCredit }, dataUser.id).then(() => {
              creditHistory.save({
                user: dataUser.id,
                amount: - dataEvent.price,
                type: "débit",
                description: "Inscription à un évènement",
                action: "register"
              }).then((response: any) => {
                const credit_id = response.data?.id;
                if (credit_id && id) {
                    setCreditId(credit_id);
                    creditHistory.updateTransactionCredit({
                        user_id: dataUser.id,
                        atelier: Number(id),
                        credit: credit_id
                    }).then((res:any) => {
                    }).catch(error => {
                    });
                }
            })
              notifications.showNotification({
                title: "Nice !",
                message: "Vous êtes maintenant inscrit ! 😀",
                color: "green",
              });
            });
          }
          request(id).then((r:any) => {
            setDataEvent(r.data);
            requestGetUser();
          });
        } else {
          setTotalPrice(dataEvent?.price - dataUser?.credit);
          setopenedPaypal(true);
        }
      } else {
        setTotalPrice(dataEvent?.price);
        setopenedPaypal(true);
      }
    }
  };

  const handleGuestWaitingZone = async () => {
    setRequestLoading(true);
    if (isInWaitingZone) {
      const result = await event.unRegisterInWaitingZone(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: " L'annulation n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        notifications.showNotification({
          title: "Nice !",
          message:
            "Vous êtes maintenant plus inscrit dans la file d'attente ! 😀",
          color: "green",
        });
        setRequestLoading(false);
      }
    }
    if (!isInWaitingZone) {
      const result = await event.registerInWaitingZone(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: " L'inscription n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        notifications.showNotification({
          title: "Nice !",
          message: "Vous êtes maintenant inscrit dans la file d'attente ! 😀",
          color: "green",
        });
        setRequestLoading(false);
      }
    }
    request(id).then((r:any) => {
      setDataEvent(r.data);
    });
  };

  useEffect(() => {
    // @ts-ignore
    request(id).then((r:any) => {
      setDataEvent(r.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const items = [
    { title: "Accueil", href: "/auth/home", ref: null },
    { title: "Évenements", href: "/auth/event", ref: null },
    {
      title:
        dataEvent?.title +
        " le " +
        format_d_MMMM_YYYY(dataEvent?.startDate),
      href: `/auth/event/detail/${id}`,
      ref: dataEvent?.site?.name,
    },
  ].map((item, index) => (
    // @ts-ignore
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  const handleRemove = async () => {
    setRequestLoading(true);
    const result = await event.remove(Number(id));
    if (!result.ok) {
      notifications.showNotification({
        title: "Une erreur est survenue.",
        message: "L'Évenement n'a pas pu être supprimée ! 🤥",
        color: "red",
      });
      setRequestLoading(false);
    } else {
      notifications.showNotification({
        title: "Nice !",
        message: "Évenement supprimée ! 😀",
        color: "green",
      });
      setRequestLoading(false);
      navigation("/auth/event", { replace: true });
    }
  };

  // @ts-ignore
  return (
    <>
      <div className="absolute left-60 top-5">
        <Link to="/auth/event">
          <Button
              className="text-sm hover:text-gray-600 hover:bg-gray-200 w-auto bg-gray-100 text-gray-400 shadow"
              color="lime"
              size="xs"
              variant="filled"
          >
            Retour à la liste
          </Button>
        </Link>
      </div>
      <div className="w-screen md:w-auto px-8 py-2 md:pb-4">
        <Breadcrumbs
          className="my-5 text-gray-800"
          separator="/"
          styles={{ separator: { color: "#333" } }}
        >
          {items}
        </Breadcrumbs>
      </div>
      <div className="bg-white shadow-xl md:flex">
        <div
          className={`py-5 px-6 ${
            !isCopilot && !isFormer ? "md:w-8/12" : "w-full mr-10"
          }`}
        >
          {/* <h2 className="font-bold mb-1 text-sm text-center md:text-left text-gray-600">{dataEvent?.thematic}</h2> */}
          <h2 className="text-4xl mb-6 font-extralight text-center md:text-left text-gray-900">
            {dataEvent?.title}
          </h2>
          {window.innerWidth < 768 ? (
            <>
              <p className="text-base text-center text-gray-600 uppercase">
                {format_d_MMMM_YYYY(dataEvent?.startDate)}
              </p>
              <p className="text-base text-center text-gray-600">
                {dataEvent?.place == "" ? (
                  <>
                    {dataEvent?.site?.address} {dataEvent?.site?.postalCode}{" "}
                    {dataEvent?.site?.city}
                  </>
                ) : (
                  dataEvent?.place
                )}
              </p>{" "}
            </>
          ) : null}

          <div className="md:flex mt-6 items-center md:mt-0 md:justify-between">
            <div className="flex">
              <img
                className="w-12 h-12 rounded-md"
                src={profilePicture}
                alt="profile"
              />
              <div className="ml-4">
                <span className="invisible text-xs text-gray-600 font-bold block">
                  PILOTE
                </span>
                <span className="text-base text-gray-600">
                  {dataEvent?.former.firstName}{" "}
                  <span className="uppercase">
                    {dataEvent?.former.lastName}
                  </span>
                </span>
              </div>
            </div>
            {window.innerWidth > 768 ? (
              <span className="text-base float-right text-gray-600 text-right uppercase">
                {format_d_MMMM_YYYY(dataEvent?.startDate)}
              </span>
            ) : null}
          </div>
          {window.innerWidth > 768 ? (
            <span className="mt-3 text-base text-gray-600 float-right">
              {dataEvent?.place == "" ? (
                <>
                  {dataEvent?.site?.address} {dataEvent?.site?.postalCode}{" "}
                  {dataEvent?.site?.city}
                </>
              ) : (
                dataEvent?.place
              )}
            </span>
          ) : null}
        </div>

        <div
          className={`m-auto text-center ${
            !isCopilot && !isFormer ? "md:w-2/12" : "hidden"
          }`}
        >
          <div className="text-center w-screen md:w-auto pb-5">
            {!isExternLink &&
              !isGuest &&
              dataEvent?.totalGuests >= dataEvent?.maxGuests &&
                !isInWaitingZone &&
              !isCopilot &&
              !isFormer && (
                <>
                  <h2 className="text-yellow-500 font-bold text-xl text-center">
                    Complet
                  </h2>
                  <button
                    onClick={() => console.log()}
                    className="w-full text-white bg-yellow-500 text-sm p-2 mt-3 font-bold rounded"
                  >
                    Voir les prochaines dates
                  </button>
                  <button
                    onClick={() => handleGuestWaitingZone()}
                    className="w-full text-white bg-gray-500 text-sm p-2 mt-3 font-bold rounded"
                  >
                    {!isInWaitingZone
                      ? "S'inscrire sur la liste d'attente"
                      : "Se retirer de la file d'attente"}
                  </button>
                  <span className="text-xs text-gray-600 float-right font-bold text-right">
                    {dataEvent?.price} €
                  </span>
                  <div className="text-xs text-gray-600 text-left">
                    Déjà{" "}
                    <span className="font-bold text-gray-600">
                      {dataEvent?.waitingZone?.length} personne(s)
                    </span>
                  </div>
                </>
              )}
            {isInWaitingZone && (
                <>
                  <button
                      onClick={() => handleGuestWaitingZone()}
                      className="w-full text-white bg-gray-500 text-thirteen p-2 mt-3 font-bold rounded"
                  >
                    {!isInWaitingZone
                        ? "S'inscrire sur la liste d'attente"
                        : "Se retirer de la file d'attente"}
                  </button>
                </>
            )}

            {!dataEvent?.isUniqueWaiting && !isGuest &&
              (!isExternLink
                ? dataEvent?.totalGuests < dataEvent?.maxGuests
                : true) &&
              !isCopilot &&
              !isFormer && dataEvent?.price > 0 ? (
                <button
                  onClick={() => handleGuest()}
                  className="bg-pink-600 w-11/12 py-5 text-white rounded-md"
                >{isExternLink ? "Je souhaite m'inscrire" : "Je m'inscris"} {dataEvent?.price} €
                </button>
              ) : (
                <button
                    onClick={() => handleGuestFree()}
                    className="bg-pink-600 w-11/12 py-5 text-white rounded-md"
                >{isExternLink ? "Je souhaite m'inscrire" : "Je m'inscris"}
                </button>
            )}
            {isGuest && (
              <div className="flex flex-col">
                <FiUserCheck size="30" className="text-green-600 mx-auto" />
                {!isExternLink || clickedLink ? (
                  <h2 className="text-green-600 font-bold text-xl text-center mb-3">
                    Déjà inscrit
                  </h2>
                ) : (
                  <a
                    onClick={() => setclickedLink(true)}
                    href={dataEvent?.externLink}
                    target="_blank"
                    className="text-green-600 font-bold text-xl text-center mb-1"
                  >
                    Clickez ici pour vous inscrire
                  </a>
                )}
                {new Date(dataEvent?.startDate) > new Date() && (
                <Button
                  onClick={() => handleGuest()}
                  size="sm"
                  color="gray"
                  variant="filled"
                >
                  <span className="inline-block mx-auto">
                    <FiUserX size="18" className="inline-block mx-auto mr-3" />{" "}
                    J'annule
                  </span>
                </Button>)}
              </div>
            )}
          </div>
          {!isGuest && dataEvent?.totalGuests < dataEvent?.maxGuests && (
            <>
              <div
                className={`flex ${
                  window.innerWidth > 1300 || window.innerWidth < 768
                    ? "justify-around md:justify-between mx-4 pb-2"
                    : "flex-col"
                } mt-1`}
              >
                <div className="text-sm text-gray-600 inline-block">
                  INSCRITS:{" "}
                  <span className="font-bold text-gray-600">
                    {dataEvent?.totalGuests} / {dataEvent?.maxGuests}
                  </span>
                </div>
              </div>
              {(creditId && dataUser?.credit > 0) && (
              <div
                className={`flex ${
                  window.innerWidth > 1459 || window.innerWidth < 768
                    ? "justify-around md:justify-between mx-4 pb-2"
                    : "flex-col"
                } mt-1`}
                >
                  <div className="text-sm text-gray-600 inline-block">
                      Numéro de crédit :{" "}
                      <span className="font-bold text-gray-600">{creditId}</span>
                  </div>
              </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="w-screen md:w-auto px-6 py-2 md:p-12 md:pt-5">
        <div className="md:flex md:justify-between mt-8">
          <div className="md:w-8/12">
            <div
              className="break-all"
              dangerouslySetInnerHTML={{ __html: dataEvent?.description }}
            />
            <div className="my-10">
              <h2 className="text-xl mb-4">Pré-requis / Objectif</h2>
              <div className="bg-white shadow p-3 rounded">
                <p
                  className="break-all"
                  dangerouslySetInnerHTML={{ __html: dataEvent?.prerequisite }}
                />
              </div>
            </div>

            <div className="my-10">
              <h2 className="text-xl mb-4">MOT DU FORMATEUR</h2>
              <div className="bg-white shadow p-3 rounded">
                <p
                  className="break-all"
                  dangerouslySetInnerHTML={{ __html: dataEvent?.formerWords }}
                />
              </div>
            </div>

            {user?.role && user?.role[0] != "ROLE_GUEST" && (
            <div className="w-full md:flex md:justify-between">
              <div className="md:w-5/12 mr-6">
                <h2 className="text-xl">PUBLIC VISÉ</h2>
                <div className="bg-white shadow p-3 mt-2 rounded">
                  {!dataEvent?.destined ||
                    (dataEvent?.destined.length == 0 && (
                      <p className="text-sm">Aucun public visé</p>
                    ))}
                  {dataEvent?.destined &&
                    dataEvent?.destined.map((name: string, index: Number) => (
                      <Badge
                        className="mx-1 md:rounded md:bg-gray-500 bg-white text-black md:text-white"
                        variant="filled"
                      >
                        {name == "ROLE_GUEST" ? "INVITÉ" : name.slice(5)}
                      </Badge>
                    ))}
                </div>
              </div>
              <div className="md:w-5/12 mt-8 md:mt-0">
                <h2 className="text-xl">HABILITATIONS NÉCESSAIRES</h2>
                <div className="bg-white shadow p-3 mt-2 rounded">
                  {!dataEvent?.habilitation ||
                    (dataEvent?.habilitation.length == 0 && (
                      <p className="text-sm">Aucune habilitation nécessaire</p>
                    ))}
                  {dataEvent?.habilitation &&
                    dataEvent?.habilitation.map(
                      (name: string, index: Number) => (
                        <Badge
                          className="mx-1 md:rounded md:bg-gray-500 bg-white text-black md:text-white"
                          variant="filled"
                        >
                          {name}
                        </Badge>
                      ),
                    )}
                </div>
              </div>
            </div>)}

            <div className="flex justify-evenly mx-auto mt-10 items-center space-x-2">
              <HasRoles role={ROLE_DXMAN_MORE} checkIsAuthor checkUser datas={dataEvent}>
                <Link to={"/auth/event/edit/" + id}>
                  <Button
                      className="text-sm hover:text-white hover:bg-yellow-600 w-auto bg-gray-100 text-gray-400 shadow"
                      color="lime"
                      size="xs"
                      variant="filled"
                  >
                    Modifier
                  </Button>
                </Link>
              </HasRoles>
              <HasRoles role={ROLE_DXMAN_MORE} checkIsAuthor checkUser datas={dataEvent}>
                <Button
                    onClick={() => handleRemove()}
                    className="text-sm hover:text-white hover:bg-red-600 w-auto bg-gray-100 text-gray-400 shadow"
                    color="lime"
                    size="xs"
                    variant="filled"
                >
                  Supprimer
                </Button>
              </HasRoles>
            </div>
            {window.innerWidth > 768 ? <div className="my-10"></div> : null}
          </div>

          <div className="md:w-3/12">
            <div>
              <h2 className="text-xl md:text-right mt-8 md:mt-0 mb-2">
                DATE ET HORAIRES
              </h2>
              <div className="bg-white shadow p-3 mt-5 rounded">
                <h2 className="text-sm text-gray-600 md:text-right">
                  DATE ET HORAIRES
                </h2>
                <h4 className="text-base text-gray-600 font-bold md:text-right">
                  {format_dddd_d_MMMM_YYYY(dataEvent?.startDate)}
                </h4>

                {dataEvent?.eventDates?.map((date: any, index: Number) => {
                  const startDate = new Date(date.dateAt);
                  startDate.setHours(startDate.getHours() - 1);
                  const endAt = new Date(date.endAt);
                  endAt.setHours(endAt.getHours() - 1);
                  return (
                    <span className="text-sm block text-gray-600 md:text-right">
                      {startDate.toLocaleTimeString("fr-FR", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}{" "}
                      -{" "}
                      {endAt.toLocaleTimeString("fr-FR", {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </span>
                  );
                })}
              </div>
            </div>
            {isExternLink && (
              <div>
                <h2 className="text-xl md:text-right mt-8 mb-2">
                  LIEN EXTERNE
                </h2>
                <a href={dataEvent?.externLink} target="_blank">
                  <p className="bg-blue-400 shadow p-3 mt-3 rounded text-center text-white">
                    Lien vers l'évenement
                  </p>
                </a>
              </div>
            )}
            {!dataEvent?.isVisio && (
              <div>
                <h2 className="text-xl md:text-right mt-8 mb-2">LIEU</h2>
                {dataEvent?.place == "" ? (
                  <>
                    <div className="bg-white shadow p-3 mt-5 rounded-b-none rounded-t text-right">
                      <h4 className="text-md">{dataEvent?.site?.address}</h4>
                      <h4 className="text-md">
                        {dataEvent?.site?.postalCode} {dataEvent?.site?.city}
                      </h4>
                    </div>
                    {dataEvent?.site?.gmap && (
                      <a
                        href={dataEvent?.site?.gmap}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          radius="xs"
                          className="bg-gray-600 px-10 rounded-none rounded-b"
                          size="md"
                          variant="filled"
                          fullWidth
                        >
                          <span className="text-sm">
                            Voir la carte interactive
                          </span>
                        </Button>
                      </a>
                    )}
                  </>
                ) : (
                  <div className="bg-white shadow p-3 mt-5 rounded-b-none rounded-t text-right text-md">
                    {dataEvent?.place}
                  </div>
                )}
              </div>
            )}
            {canSeeGuest && (
                <>
            <div className="bg-white shadow p-3 mt-8 rounded text-center">
              {dataEvent?.totalGuests >= dataEvent?.maxGuests ? (
                <h2 className="text-yellow-500 font-bold text-xl text-center">
                  Complet
                </h2>
              ) : (
                <>
                  <h2 className="text-pink-600 text-3xl font-bold ">
                    {dataEvent?.totalGuests} inscrits
                  </h2>
                  {!isExternLink && (
                    <h2 className="text-pink-600 text-xl ">
                      sur {dataEvent?.maxGuests} places
                    </h2>
                  )}
                </>
              )}

              <Spoiler
                maxHeight={60}
                showLabel="Voir plus d'inscrits"
                hideLabel="Moins"
                transitionDuration={100}
              >
                <div className="pb-5">
                  {dataEvent?.guests.map((guest: User, index: Number) => (
                    <div key={"" + index} className="mt-2 mb-1">
                      <p className="text-sm">
                        {guest.lastName} {guest.firstName}
                      </p>
                    </div>
                  ))}
                </div>
              </Spoiler>
            </div>
                  {dataEvent?.totalGuests >= dataEvent?.maxGuests && (
                      <div className="bg-white shadow p-3 mt-8 rounded text-center">
                        <h2 className="text-pink-600 text-thirteen font-bold ">
                          {dataEvent?.waitingZone?.length} inscrits
                        </h2>
                        <Spoiler
                            maxHeight={60}
                            showLabel="Voir plus d'inscrits"
                            hideLabel="Moins"
                            transitionDuration={100}
                        >
                          <div className="pb-5">
                            {dataEvent?.waitingZone.map(
                                (guest: User, index: Number) => (
                                    <div key={"" + index} className="mt-2 mb-1">
                                      <p className="text-thirteen">
                                        {guest.lastName} {guest.firstName}
                                      </p>
                                    </div>
                                )
                            )}
                          </div>
                        </Spoiler>
                      </div>
                  )}
            </>
            )}
            {!isGuest && dataEvent?.totalGuests >= dataEvent?.maxGuests && !isInWaitingZone && (
              <>
                <Button
                  onClick={() => console.log()}
                  className="px-10 mt-3"
                  size="md"
                  color="orange"
                  variant="filled"
                  fullWidth
                >
                  <span className="text-sm">Voir les prochaines dates</span>
                </Button>
                <Button
                  onClick={() => handleGuestWaitingZone()}
                  className="px-10 mt-5"
                  size="md"
                  color="gray"
                  variant="filled"
                  fullWidth
                >
                  <span className="text-sm">
                    {!isInWaitingZone
                      ? "S'inscrire sur la liste d'attente"
                      : "Se retirer de la file d'attente"}
                  </span>
                </Button>
              </>
            )}
            {isInWaitingZone && (
                <>
                  <button
                      onClick={() => handleGuestWaitingZone()}
                      className="w-full text-white bg-gray-500 text-thirteen p-2 mt-3 font-bold rounded"
                  >
                    {!isInWaitingZone
                        ? "S'inscrire sur la liste d'attente"
                        : "Se retirer de la file d'attente"}
                  </button>
                </>
            )}

            {window.innerWidth < 768 ? (
              <div className="text-center mt-8">
                {!dataEvent?.isUniqueEvent && !isGuest && dataEvent?.totalGuests < dataEvent?.maxGuests && (
                  <button
                    onClick={() => handleGuest()}
                    className="bg-pink-600 w-full py-5 text-white rounded-md"
                  >
                     {isExternLink ? "Je souhaite m'inscrire" : "Je m'inscris"} {dataEvent?.price} €
                  </button>
                )}
                {dataEvent?.isUniqueEvent && isInWaitingZone && !isGuest && dataEvent?.totalGuests < dataEvent?.maxGuests && (
                  <button
                    onClick={() => handleGuest()}
                    className="bg-pink-600 w-full py-5 text-white rounded-md"
                  >
                     {isExternLink ? "Je souhaite m'inscrire" : "Je m'inscris"} {dataEvent?.price} €
                  </button>
                )}
                {isGuest && (
                  <>
                    <FiUserCheck size="30" className="text-green-600 mx-auto" />
                    {!isExternLink || clickedLink ? (
                      <h2 className="text-green-600 font-bold text-xl text-center mb-3">
                        Déjà inscrit
                      </h2>
                    ) : (
                      <a
                        onClick={() => setclickedLink(true)}
                        href={dataEvent?.externLink}
                        target="_blank"
                        className="text-green-600 font-bold text-xl text-center mb-1"
                      >
                        Clickez ici pour vous inscrire
                      </a>
                    )}
                    {new Date(dataEvent?.startDate) > new Date() && (
                    <Button
                      onClick={() => handleGuest()}
                      size="xl"
                      color="gray"
                      variant="filled"
                    >
                      <span className="inline-block mx-auto">
                        <FiUserX
                          size="18"
                          className="inline-block mx-auto mr-3"
                        />{" "}
                        J'annule
                      </span>
                    </Button>)}
                  </>
                )}

                <div className="flex justify-between mt-1">
                  <div className="text-sm text-gray-600 inline-block">
                    INSCRITS:{" "}
                    <span className="font-bold text-gray-600">
                      {dataEvent?.totalGuests} / {dataEvent?.maxGuests}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
            <button
              onClick={() => {
                clipboard2.copy(
                  dataEvent?.guests.map((guest: any) => guest.email).join(", "),
                );
                setOpened((o) => !o);
              }}
              className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full"
            >
              <p
                className={`flex text-gray-400 font-medium float-right ${
                  clipboard2.copied ? "text-green-400" : "text-gray-400"
                }`}
              >
                {clipboard2.copied ? "Copié" : "Copier les emails INSCRITS"}{" "}
                <FaCopy className="ml-1 mt-1" />
              </p>
            </button>

            <button
                onClick={() => {
                  clipboard.copy(
                      dataEvent?.guests
                          .map((guest: any) => guest.godFather ? guest.godFather.email ?? "aucun" : "aucun")
                          .join("; "),
                  );
                  setOpened((o) => !o);
                }}
                className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full"
            >
              <p
                  className={`flex text-gray-400 font-medium bg-white p-2 text-thirteen float-right ${
                      clipboard.copied ? "text-green-400 " : "text-gray-400"
                  }`}
              >
                {clipboard.copied ? "Copié" : "Copier les emails PARRAINS"}{" "}
                <FaCopy className="ml-1 mt-1" />
              </p>
            </button>

            <button className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full">
              {!loading && csv && csv.length > 0 && (
                <p className="flex text-gray-400 font-medium float-right">
                  {

                   <CSVLink
                       separator=";"
                    filename={"liste de " + dataEvent?.title + ".csv"}
                    data={csv}
                  >
                    Extraire la liste{" "}
                  </CSVLink>

                  }
                  <RiFolderSharedLine className="ml-1 mt-1" />
                </p>
              )}
            </button>

            {dataEvent?.isVisio && (
              <div className="col-span-1 lg:col-span-2 mr-10 mt-5">
                <h2 className="text-xl text-right my-2">VISIOCONFERENCE</h2>
                <div className="bg-white shadow p-3 mt-3 rounded-b-none rounded-t">
                  <h4 className="text-xs">Participer à la réunion Zoom</h4>
                  <Divider className="my-2" />
                  <Tooltip
                    className="float-right justify-center pb-2"
                    wrapLines
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Copier le lien"
                  >
                    <Tooltip
                      delay={500}
                      label="Copié !"
                      position="right"
                      opened={opened}
                    >
                      <ActionIcon
                        className="float-right justify-center pb-2"
                        component="button"
                        onClick={() => {
                          clipboard.copy(dataEvent?.videoConferenceLink);
                          setOpened((o) => !o);
                        }}
                      >
                        <IoIosCopy size={15} />
                      </ActionIcon>
                    </Tooltip>
                  </Tooltip>
                  <Tooltip
                    className="float-right justify-center pb-2"
                    wrapLines
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Ouvrir le lien"
                  >
                    <ActionIcon
                      className="float-right justify-center pb-2"
                      component="button"
                      onClick={() =>
                        window.open(dataEvent?.videoConferenceLink, "_blank")
                      }
                    >
                      <IoMdOpen size={15} />
                    </ActionIcon>
                  </Tooltip>
                  <h4 className="text-xs ">Lien</h4>
                  <Divider className="my-2" />
                  <Tooltip
                    className="float-right pb-2"
                    wrapLines
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Copier le mot de passe"
                  >
                    <Tooltip
                      delay={500}
                      position="right"
                      label="Copié !"
                      opened={opened2}
                    >
                      <ActionIcon
                        component="button"
                        className="float-right justify-center pb-2"
                        onClick={() => {
                          clipboard.copy(dataEvent?.visioPassword);
                          setOpened2((o) => !o);
                        }}
                      >
                        <IoIosCopy size={15} />
                      </ActionIcon>
                    </Tooltip>
                  </Tooltip>
                  <h4 className="text-xs">Mot de passe :</h4>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
      {/* Bouton je m'inscris qui suis quand on scroll */}
      {window.innerWidth > 768
        ? null
        : scroll.y > 450 && (
            <div className="fixed top-0 bg-white shadow-xl pb-3">
              <div className="pb-2 pt-3 px-6 md:w-8/12">
                <h2 className="text-xs mb-1 font-extralight text-gray-900 uppercase">
                  {dataEvent?.title}
                </h2>
                <div className="flex justify-between">
                  <p className="text-xs text-gray-600 uppercase">
                    {format_d_MMMM_YYYY(dataEvent?.startDate)}
                  </p>
                  <p className="text-xs text-center text-gray-600 uppercase">
                    {dataEvent?.place}
                  </p>
                </div>
              </div>
              <div className="m-auto text-center md:w-2/12">
                <div className="text-center w-screen md:w-auto px-3">
                  {!isGuest && dataEvent?.totalGuests >= dataEvent?.maxGuests && (
                    <>
                      <h2 className="text-yellow-500 font-bold text-xm text-center">
                        Complet
                      </h2>
                      <button
                        onClick={() => console.log()}
                        className="w-full text-white bg-yellow-500 text-xs p-2 mt-1 font-bold rounded"
                      >
                        Voir les prochaines dates
                      </button>
                      <button
                        onClick={() => handleGuestWaitingZone()}
                        className="mb-1 w-full text-white bg-gray-500 text-xs p-2 mt-2 font-bold rounded"
                      >
                        {!isInWaitingZone
                          ? "S'inscrire sur la liste d'attente"
                          : "Se retirer de la file d'attente"}
                      </button>
                      <div className="text-xs text-gray-600 text-left">
                        Déjà{" "}
                        <span className="font-bold text-gray-600">
                          {dataEvent?.waitingZone?.length} personne(s)
                        </span>
                      </div>
                    </>
                  )}

                  {!dataEvent?.isUniqueEvent && !isGuest && dataEvent?.totalGuests < dataEvent?.maxGuests && (
                    <button
                      onClick={() => handleGuest()}
                      className="bg-pink-600 object-contain text-white rounded-md flex justify-center float-right"
                    >
                      <p className="py-2 px-3 text-xs">
                        {isExternLink
                          ? "Je souhaite m'inscrire"
                          : "je m'inscris"} {dataEvent?.price} €
                      </p>
                    </button>
                  )}
                  {dataEvent?.isUniqueEvent && isInWaitingZone && !isGuest && dataEvent?.totalGuests < dataEvent?.maxGuests && (
                    <button
                      onClick={() => handleGuest()}
                      className="bg-pink-600 object-contain text-white rounded-md flex justify-center float-right"
                    >
                      <p className="py-2 px-3 text-xs">
                        {isExternLink
                          ? "Je souhaite m'inscrire"
                          : "je m'inscris"} {dataEvent?.price} €
                      </p>
                    </button>
                  )}
                  {isGuest && (
                    <>
                      <FiUserCheck
                        size="25"
                        className="text-green-600 mx-auto"
                      />
                      {!isExternLink || clickedLink ? (
                        <h2 className="text-green-600 font-bold text-xl text-center mb-3">
                          Déjà inscrit
                        </h2>
                      ) : (
                        <a
                          onClick={() => setclickedLink(true)}
                          href={dataEvent?.externLink}
                          target="_blank"
                          className="text-green-600 font-bold text-xl text-center mb-1"
                        >
                          Clickez ici pour vous inscrire
                        </a>
                      )}
                      {new Date(dataEvent?.startDate) > new Date() && (
                      <Button
                        onClick={() => handleGuest()}
                        className="px-10"
                        size="md"
                        color="gray"
                        variant="filled"
                      >
                        <span className="inline-block mx-auto">
                          <FiUserX
                            size="16"
                            className="inline-block mx-auto mr-3 text-xs"
                          />{" "}
                          J'annule
                        </span>
                      </Button>)}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
    </>
  );
};

export default ItemEventWrapped;
