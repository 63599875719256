import React, { useState, useEffect } from "react";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Checkbox,
  LoadingOverlay,
} from "@mantine/core";
import GoalNeedValidationItem from "../../../components/goals/GoalNeedValidationItem";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEuro, AiOutlineEye } from "react-icons/all";
import { Modal } from "@mantine/core";
import ButtonWrapper from "../../../components/paypal/ButtonWrapper";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import useApi from "../../../hooks/useApi";
import users from "../../../api/users";
import { useNotifications } from "@mantine/notifications";
import useAuth from "../../../auth/useAuth";
import storage from "../../../auth/storage";
import transactions from "../../../api/transactions";
import creditHistory from "../../../api/creditHistory";
//@ts-ignore
import cgu from "../../../assets/images/cgu.pdf";

const AdhesionScreen = () => {
  const {id}: any = storage.getUser();
  const [Vcgu, setVcgu] = useState(false);
  const [opened, setOpened] = useState(false);
  const [Completed, setCompleted] = useState(false);
  const [canCredit, setCanCredit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(50);
  const { data: dataUser, request: requestMe } = useApi(users.getUserById);
  const { data: dataUserMe, request: requestUserMe } = useApi(users.me);
  const { request: requestNeoUser } = useApi(users.editNeoMan);
  const { request: requestAdhesionUpdate } = useApi(users.adhesionUpdate);
  const { request: requestAdhesion } = useApi(users.adhesion);
  const {request: requestEditUser} = useApi(users.edit);
  const navigation = useNavigate();
  const notifications = useNotifications();
  const {request: requestTransaction} = useApi(transactions.CreateTransaction)
  const date = new Date();
  const { logout } = useAuth();
  let navigate = useNavigate();
  const [transactionAdhesion, setTransactionAdhesion] = useState(null);
  const [creditId, setCreditId] = useState<string | number | null>(null);
  const [usedCreditsIds, setUsedCreditsIds] = useState<(string | number)[]>([]);
  const [partialCredits, setPartialCredits] = useState<{ id: string | number; remaining_amount: number }[]>([]);
  const [totalCredit, setTotalCredit] = useState(0);


  const handlePaid = () => {
    const date = new Date();
    requestMe(id).then((res) => {
      const user_id = res?.data?.id;

      requestTransaction({
        user: res?.data?.id ?? null,
        email: res?.data?.email ?? '',
        lastName: res?.data?.lastName ?? '',
        firstName: res?.data?.firstName ?? '',
        society: res?.data?.society ?? null,
        city: res?.data?.city ?? null,
        address: res?.data?.address ?? null,
        postalCode: res?.data?.postalCode ?? null,
        createAt: (date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()),
        paypalIdTransaction: "STRIPE PAYMENT",
        paypalAccountStatus: "COMPLETED",
        //@ts-ignore
        paymentPaypalDate: (date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()),
        paymentMethod: "PAYMENT_BY_CREDIT",
        total: price.toString(),
        quantity: 1,
        itemName: price > 30 ? "Renouvellement adhésion forman" : "Adhésion Forman" + id,
        workshop: undefined,
        formation: undefined,
        idWorkshop: undefined,
        IdFormation: undefined
      })
          .then((res1) => {
            //Update credit
            if(
              canCredit &&
              totalCredit>0 &&
              usedCreditsIds && 
              usedCreditsIds.length
            ){
              creditHistory.updateCreditPaiementEchec({
                user_id: user_id,
                credit_ids: usedCreditsIds
              }).then((res:any) => {
                if(partialCredits && partialCredits.length){
                  creditHistory.updateCreditPartialEchec({
                    user_id: user_id,
                    credit_partials: partialCredits
                  })
                }
              }).catch(error => {
              });
            }

            const newCredit = res.data.credit - price;
            const transaction_id = res1?.data?.id;
            requestEditUser({credit: newCredit}, id).then(() => {
              creditHistory.save({
                user: id,
                amount: - price,
                type: "débit",
                description: price > 30 ? "Renouvellement adhésion forman" : "Adhésion Forman",
                action: "register_adhesion"
              }).then((response: any) => {
                  const credit_id = response?.data?.id;
                  if (credit_id) {
                      setCreditId(credit_id);
                      creditHistory.updateTransactionCredit({
                          user_id: user_id,
                          credit: credit_id,
                          transaction_id: transaction_id
                      }).then((res:any) => {
                      }).catch(error => {
                      });
                  }
              })
            });
            // @ts-ignore
            if (res.data.roles[0] == "ROLE_GUEST" || res.data.roles[0] == "ROLE_NOMAN") {
              requestNeoUser("NEOMAN", id);
              requestAdhesion(id);
              notifications.showNotification({
                title: "Nice !",
                message: "Vous êtes maintenant NEOMAN ! 😀",
                color: "green",
              });
              logout();
              navigate('/');
            } else {
              requestAdhesionUpdate(id);
              notifications.showNotification({
                title: "Nice !",
                message: "Vous avez renouvellez votre adhésion ! 😀",
                color: "green",
              });
            }
            logout();
            navigate('/');
          })
    })
  }

  useEffect(() => {
    setLoading(true);
    requestUserMe().then((res)=>{
      let currentPrice = 60
      let credit = res.data.credit;
      let user_id = res.data.id;
      let setted = false;
      if(res.data.roles[0] == "ROLE_XMAN"){
        setPrice(60);
        currentPrice = 60;
        setted = true;
      }
      if(res.data.roles[0] == "ROLE_DXMAN"){
        setPrice(60);
        currentPrice = 60;
        setted = true;
      }
      if(res.data.roles[0] == "ROLE_DEVMAN"){
        setPrice(60);
        currentPrice = 60;
        setted = true;
      }
      if(res.data.roles[0] == "ROLE_GUEST"){
        setPrice(30);
        currentPrice = 30;
        setted = true;
      }
      if(!setted){
        setPrice(currentPrice);
      }
      let totalCredits: number = 0; 
      let totalEchecCredit: number = 0; 
      let usedCredits: (string | number)[] = [];
      let partialCreditUsed: boolean = false;
      let partialCredits: { id: string | number; remaining_amount: number }[] = [];


      if(credit){
        creditHistory.getListCreditEchec({
          user_id: user_id
        }).then((response:any) => {
          let results = response?.data || [];
          if(results && results.length > 0){
            results.forEach((credit_history: any)  => {
              totalCredits += credit_history.amountLeft;
            });
            setTotalCredit(totalCredits);
            for (let i = 0; i < results.length; i++) {
                let credit_history = results[i];
                if (totalEchecCredit < currentPrice) {
                  let remainingAmount = currentPrice - totalEchecCredit;
                  if (credit_history.amountLeft <= remainingAmount) {
                      totalEchecCredit += credit_history.amountLeft; 
                      usedCredits.push(credit_history.id); 
                      credit_history.amountLeft = 0;
                  } else {
                      totalEchecCredit += remainingAmount;
                      usedCredits.push(credit_history.id);
                      partialCredits.push({ 
                        id: credit_history.id, 
                        remaining_amount: credit_history.amountLeft - remainingAmount 
                      });
                      credit_history.amountLeft -= remainingAmount;
                      partialCreditUsed = true; 
                      break;
                  }

                  if (totalEchecCredit >= currentPrice) {
                      break;
                  }
              }
            }
            if (
              totalEchecCredit >= currentPrice &&
              credit && 
              credit >= currentPrice &&
              credit >= totalEchecCredit
            ) {
              setUsedCreditsIds(usedCredits);
              setPartialCredits(partialCredits);
              setCanCredit(true);
            }
          }
        }).catch(error => {
        });

      }
      /*if(res.data.credit >= currentPrice){
        setCanCredit(true);
      }*/
    });
    setLoading(false);
  }, []);

  useEffect(() => {
    if (Completed === true) {
      setOpened(false);
      setLoading(true);
      if (dataUser.roles == "ROLE_GUEST" || dataUser.roles == "ROLE_NOMAN") {
        console.log("datauser",id);
        requestNeoUser("NEOMAN", id);
        requestAdhesion(id);
        notifications.showNotification({
          title: "Nice !",
          message: "Vous êtes maintenant NEOMAN ! 😀",
          color: "green",
        });
        logout();
        navigate('/');
      } else {
        console.log("datauser2",id);
        requestAdhesionUpdate(id);
        notifications.showNotification({
          title: "Nice !",
          message: "Vous avez renouvellez votre adhésion ! 😀",
          color: "green",
        });
      }
      setCompleted(false);
      setLoading(false);
    }
  }, [Completed]);

  const notifVcgu = () => {
    notifications.showNotification({
      title: "Une erreur est survenue.",
      message: "Vous devez accepter les CGU ! 🤥",
      color: "red",
    });
  };

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Adhésion", href: "/auth/notifications/adhesion" },
    { title: "Notifications", href: `/auth/home` },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <>
      <div className="w-screen md:w-auto px-6 py-2 md:p-12 mb-28">
        <LoadingOverlay visible={loading} />
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl ">
          <span className="font-bold text-xl">NOTIFICATIONS</span>
          <br />
          <span className="text-3xl">ADHÉSION</span>
        </div>
        <div className="mt-6 md:mt-10 text-xl "></div>
        <div className="mt-3 md:mt-3 text-xl ">
          {Completed ? (
            <div className="bg-fushiaTonique rounded shadow flex flex-col items-center p-5 text-white">
              <h1>Félicitaion, vous avec adhérez à Forman !</h1>
              <div className="flex">
                <p>Retour à l'acueil</p>
                <Link className="ml-1 underline" to={"/auth/home"}>
                  ici
                </Link>
              </div>
            </div>
          ) : (
            <div className="bg-fushiaTonique rounded shadow flex flex-col lg:flex-row gap-x-6 gap-y-3 p-5">
              <div className="flex flex-col flex-1 gap-2">
                <span className="uppercase text-white font-bold">
                  PAYEZ VOTRE ADHÉSION
                </span>
              </div>
              <div className="flex flex-col justify-end gap-2">
                <span className="uppercase text-white font-bold">{price} euros</span>
                {(totalCredit>0) && (
                  <span className="uppercase text-white font-bold">
                    Crédit: {totalCredit}  €
                  </span>
                )}
                {
                  !canCredit ? (
                <div>
                  <Button
                    onClick={() => (Vcgu ? setOpened(true) : notifVcgu())}
                    className="bg-white hover:bg-yellow-200"
                  >
                    <AiOutlineEuro size={18} className="text-fushiaTonique" />{" "}
                    <span className="ml-3 text-fushiaTonique hover:bg-stone-200 ">
                      Procéder au paiement
                    </span>
                  </Button>
                </div>
                  ) : (
                      <div>
                        <Button
                            onClick={() => (Vcgu ? handlePaid() : notifVcgu())}
                            className="bg-white hover:bg-yellow-200"
                        >
                          <AiOutlineEuro size={18} className="text-fushiaTonique" />{" "}
                          <span className="ml-3 text-fushiaTonique hover:bg-stone-200 ">
                      Procéder au paiement crédit
                    </span>
                        </Button>
                      </div>
                  )}
                <Checkbox
                  className="z-20 mt-1"
                  required={true}
                  checked={Vcgu}
                  onChange={(event) => setVcgu(event.currentTarget.checked)}
                  label={
                    <>
                      <span className="text-white">
                        Je reconnais avoir pris connaissance des{" "}
                        <a className="underline text-xs text-white" href={cgu}>
                          CGU
                        </a>{" "}
                        et les accepter pleinement
                      </span>
                    </>
                  }
                />
                <Modal
                  opened={opened}
                  onClose={() => setOpened(false)}
                  title="Paiement par Paypal"
                >
                  <ButtonWrapper
                    currency="EUR"
                    showSpinner={false}
                    amount={price}
                    isAdhesion={true}
                    itemName={price > 30 ? "renouvellement cotisation Forman" : "Adhésion Forman"}
                    Completed={() => setCompleted(true)}
                  />
                </Modal>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AdhesionScreen;
