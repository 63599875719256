import {
  Button,
} from "@mantine/core";
import storage from "../../auth/storage";
import { Link } from "react-router-dom";
import { AiOutlineDownload } from "react-icons/ai";
import { IoEyeSharp } from "react-icons/io5";
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  Table as TablePDF,
  TableCell,
  TableHeader,
} from "@david.kucsai/react-pdf-table";
import isAdhesionPayment from "../../utility/isAdhesionPayment";
import getSiret from "../../utility/getSiret";
import {
    getPriceHTBase,
    getPriceTVABase,
    getNumberTVA,
} from "../../utility/tva";
import { REACT_APP_API_BASE_URL } from "../../env";

interface Props {
    element: any;
    valueTVA: number
}

const InvoiceRow = (props: Props) => {

    const {isAdmin}: any = storage.getUser();
    const userCode = isAdmin ? "admin" : "expert-comptable";

    const getPriceHT = (price: string, tva: number, itemName: string) => {
        if (isAdhesionPayment(itemName)) {
          return parseInt(price);
        }
        return getPriceHTBase(price, tva);
    };
    
    const getPriceTVA = (price: string, tva: number, itemName: string) => {
        if (isAdhesionPayment(itemName)) {
        return 0;
        }
        return getPriceTVABase(price, tva);
    };
      
    const paymentMethod = (props.element.paymentMethod == 'PAYMENT_BY_CREDIT') ? '\n(Paiement par crédit)' : ''
    const startDate = new Date(props.element.workshop ? props.element.workshop.startDate : props.element.formation ? props.element.formation.startDate : new Date()).toLocaleDateString('fr-FR', { weekday:"long", year:"numeric", month:"short", day:"numeric"})
    
    return (
        <tr key={props.element.id} className="hover:bg-white cursor-pointer">
            <td>
                {props.element.user_id}
            </td>
            <td>
                {props.element.user_lastName} {props.element.user_firstName}
            </td>
            <td>
                {new Date(props.element.createAt).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                })}
            </td>
            <td>{props.element.id}</td>
            <td>{props.element.total}</td>
            <td>
                <div className=" flex flex-row bg-transparent justify-end props.elements-center text-2xl">
                    {props.element.chorusProId ? (
                        <a href={`${REACT_APP_API_BASE_URL}/api/chorus-pro/get-invoice/${props.element.id}`}>
                            <button>
                                <AiOutlineDownload/>
                            </button>
                        </a>
                    ) : (
                    <PDFDownloadLink
                        document={
                            <Document>
                                <Page
                                    size="A4"
                                    style={{
                                        backgroundColor: "#FFF",
                                        display: "flex",
                                        flexDirection: "column",
                                    }}
                                >
                                    <View style={{marginTop: 10, marginLeft: 10}}>
                                        <Text>FACTURE N° {props.element.id}</Text>
                                        <Text style={{fontSize: 15}}>
                                            {new Date(props.element.createAt).toLocaleDateString(
                                                "fr-FR",
                                                {
                                                    year: "numeric",
                                                    month: "numeric",
                                                    day: "numeric",
                                                },
                                            )}
                                        </Text>
                                    </View>

                                    <View
                                        style={{
                                            marginTop: 10,
                                            marginLeft: 10,
                                            fontSize: 10,
                                        }}
                                    >
                                        <Text>Association Forman </Text>
                                        <Text>2 bis rue Léon Blum </Text>
                                        <Text>78350 JOUY-EN-JOSAS</Text>
                                    </View>

                                    <View
                                        style={{
                                            textAlign: "right",
                                            fontSize: 10,
                                            marginRight: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <Text>Identifiant Client :</Text>
                                        <Text>{props.element.user_id}</Text>
                                    </View>

                                    <View
                                        style={{
                                            textAlign: "right",
                                            fontSize: 10,
                                            marginRight: 10,
                                        }}
                                    >
                                        <Text>Adresse de facturation Client :</Text>
                                        <Text>
                                            {props.element.user_societyName ? props.element.user_societyName : props.element.user_lastName + " " + props.element.user_firstName}
                                        </Text>
                                        <Text>{props.element.user_address}</Text>
                                        <Text>
                                            {props.element.user_city} {props.element.user_postalCode}
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            marginTop: 100,
                                            marginLeft: 10,
                                            marginRight: 10,
                                            backgroundColor: "gray",
                                            fontSize: 12,
                                        }}
                                    >
                                        <TablePDF>
                                            <TableHeader textAlign="center" fontSize="12">
                                                <TableCell textAlign="center">Quantité</TableCell>
                                                <TableCell textAlign="center">
                                                    Description
                                                </TableCell>
                                                <TableCell textAlign="center">
                                                    Prix Unitaire (euros HT)
                                                </TableCell>
                                                <TableCell textAlign="center">
                                                    Montant (euros HT)
                                                </TableCell>
                                            </TableHeader>
                                        </TablePDF>
                                    </View>
                                    <View style={{marginLeft: 10, marginRight: 10}}>
                                        <TablePDF>
                                            <TableHeader textAlign="center" fontSize="12">
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                    {props.element.quantity}
                                                </TableCell>
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                    {(props.element.itemName ?? "" + startDate) + paymentMethod}
                                                </TableCell>
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                    {getPriceHT(props.element.total, props.valueTVA, props.element.itemName)}
                                                </TableCell>
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                    {getPriceHT(props.element.total, props.valueTVA, props.element.itemName)}
                                                </TableCell>
                                            </TableHeader>
                                        </TablePDF>
                                        <TablePDF>
                                            <TableHeader textAlign="center" fontSize="12">
                                                <TableCell></TableCell>
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >{startDate}</TableCell>
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                    <Text> {"TVA"} ({isAdhesionPayment(props.element.itemName) ? 0 : props.valueTVA ?? 0}{" %"})</Text>
                                                </TableCell>
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                    <Text> {getPriceTVA(props.element.total, props.valueTVA, props.element.itemName)}</Text>
                                                </TableCell>
                                            </TableHeader>
                                        </TablePDF>
                                        <TablePDF>
                                            <TableHeader textAlign="center" fontSize="12">
                                                <TableCell
                                                style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                Date de paiement
                                                </TableCell>
                                                <TableCell
                                                style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                {new Date(props.element.createAt).toLocaleDateString(
                                                    "fr-FR",
                                                    {
                                                        year: "numeric",
                                                        month: "numeric",
                                                        day: "numeric",
                                                    },
                                                )}
                                                </TableCell>
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                    <Text> {"TOTAL (TTC)"}</Text>
                                                </TableCell>
                                                <TableCell
                                                    style={{paddingTop: 5, paddingBottom: 5}}
                                                >
                                                    <Text>{props.element.total}</Text>
                                                </TableCell>
                                            </TableHeader>
                                        </TablePDF>
                                        <View style={{fontSize: 10, marginTop: 10}}>
                                            {isAdhesionPayment(props.element.itemName) ? <Text>{" TVA non applicable,article 261 du CGI"}</Text> : <></>}
                                            <Text>

                                                {"indemnité forfaitaire pour frais de recouvrement de 40 euros"}
                                            </Text>

                                            {props.element?.credit && <Text>
                                              Numéro de l’avoir : {props.element?.credit?.split("/")?.pop()}
                                            </Text>}
                                            
                                        </View>
                                    </View>
                                    <View style={{fontSize: 10, width: "100%", textAlign: "center", position: "absolute", left: 0, bottom: 10}}>
                                        <Text>Facture n° {props.element.id} - Association Forman - Siret : {getSiret()} - TVA n° : {getNumberTVA()}</Text>
                                    </View>
                                </Page>
                            </Document>
                        }
                        fileName={`Facture Forman n°${props.element.id}.pdf`}
                    >
                        {({blob, url, loading, error}) =>
                            loading ? (
                                <button>Loading PDF.. </button>
                            ) : (
                                <button>
                                    <AiOutlineDownload/>
                                </button>
                            )
                        }
                    </PDFDownloadLink>
                    )}
                    <Link target="_blank" to={`/auth/${userCode}/user/${props.element.user_id}/facture/${props.element.id}`}>
                        <Button
                            className="bg-transparent text-2xl hover:bg-blue-200"
                            radius="xl"
                        >
                            <IoEyeSharp style={{color: "#1e3a8a"}}/>
                        </Button>
                    </Link>
                </div>
            </td>
        </tr>
    )
};

export default InvoiceRow;
